<template>
    <div>
  <section class="vh-100">
    <div class="container mt-5 h-90">
      <b-row class="text-center">
        <b-col>
          <img src="../assets/images/logo-3.png" alt="Ping Monk" style="height:10vh;" />
        </b-col>
      </b-row>
      <b-row class="text-center mt-4 mb-4">
        <b-col>
          <h1 style="font-size: 50px; font-weight: 900; color: white">
            Create your <span style="color: #f7981e">FREE account.</span>
          </h1>
        </b-col>
      </b-row>
    </div>

    <div class="container h-50">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <div class="card" style="border-radius: 1rem">
            <div class="card-body p-5 text-left">
            <form action="" @submit.prevent="Register()">
            <div class="form-outline mb-4">
                <label class="form-label" for="typeUsername-2">Your full name</label>
                <input
                  v-model="input.username"
                  :state="validateFullName"
                  maxlength="50"
                  type="text"
                  id="typeUsername-2"
                  class="form-control form-control-lg"
                  placeholder="e.g John Doe" 
                  required
                />
                <b-form-invalid-feedback :state="validateFullName">{{ fullname_err_text }}</b-form-invalid-feedback>
              </div>
              <div class="form-outline mb-4">
                <label class="form-label" for="typeEmailX-2">Your e-mail</label>
                <input
                  v-model="input.email"
                  type="email"
                  maxlength="50"
                  id="typeEmailX-2"
                  class="form-control form-control-lg"
                  placeholder="e.g info@example.com" 
                  autocomplete="email"
                  required
                />
                
              </div>

              <div class="form-outline mb-4">
                <label class="form-label" for="typePasswordX-2">Enter new password </label><small> (min. 6 characters)</small>
                <b-input-group>
                  <b-form-input
                    v-model="input.password"
                    type="password"
                    id="newpassword"
                    class="form-control form-control-lg"
                    placeholder="**********"
                    maxLength="30"
                    autocomplete="newpassword"
                    :state="validatePassword"
                    required
                  />
                  <b-input-group-append is-text @click="togglePassword('newpassword')">
                    <i v-show="showPassword == true" class="icofont icofont-eye" id="togglePassword" title="hide password"></i>
                    <i v-show="showPassword == false" class="icofont icofont-eye-blocked" id="togglePassword" title="show password"></i>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="form-outline mb-4">
                <label class="form-label" for="typePasswordX-2">Reenter password </label>
                <input
                  v-model="verify_password"
                  type="password"
                  id="reenterpassword"
                  class="form-control form-control-lg"
                  placeholder="**********"
                  maxLength="30"
                  autocomplete="password"
                  required
                />
                
              </div>

              <b-button class="btn btn-lg btn-block btn-success" type="inc">
                Register Now
              </b-button>
              <div class="mt-2 text-center p-2 google_btn" @click="OngoogleLogin()">
                <img width="15px" style="margin-bottom:3px; margin-right:5px" alt="Google login" src="../assets/images/google_logo.webp" />
                    Sign Up with Google
              </div>
              <hr class="my-4" />

              <div class="form-outline mb-4 text-center">
                <router-link style="color:#F7981E;" tag="a" to="/auth/login" custom v-slot="{ navigate }">
                  <span @keypress.enter="navigate" @click="navigate" class="cursor_pointer">
                    <h6>Already have an account?</h6>
                  </span>
                </router-link>
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import UserController from "../../services/userController"
import Config from "../../services/config"
import Userauth from "../auth/js/index";
// import TagManager from "../../services/tagmanager"
    export default {
        name: 'signUp',
        data(){
          return{
            showPassword:false,
            fullname_err_text:'',
            verify_password:'',
            input:{email:'',username:'',password:'',source:'',clickid:'',refererid:''},
            googleLogin:''
          }
        },
        mounted(){
          if(this.$route.query.email){
            this.input.email = this.$route.query.email
          }
          if(this.$route.query.source){
            this.input.source = this.$route.query.source
            this.$root.$data.source = this.input.source
              // TagManager.callTagManager(this.input.source)
              // TagManager.SendConversion(this.input.source)
          }
          if(this.$route.query.refererid){
            this.input.refererid = this.$route.query.refererid
          }
          if(this.$route.query.clickid){
            this.input.clickid = this.$route.query.clickid
            this.$root.$data.clickid = this.$route.query.clickid}
            if(this.$root.$data.clickid){this.input.clickid = this.$root.$data.clickid}
            if(this.$root.$data.source){this.input.source = this.$root.$data.source}
            this.googleLogin = Config.googleLogin+'?source='+this.input.source+'&clickid='+this.input.clickid+'&refererid='+this.input.refererid
            if(Userauth.isAuthenticated() && Userauth.checAlive()){
                let user  = JSON.parse(window.localStorage.getItem('userinfo'))
                if(user.role == 'manager'){
                    this.$router.replace("/manager/users");
                }
                else{
                    this.$router.replace("/app/dashboard");
                }
                this.$toasted.show('Successfully Logged In to - '+user.email, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
            }
          },
        computed:{
          validateFullName() {
            var fullName = this.input.username
            const regex = /^[a-zA-Z0-9 ]+$/; // Alphanumeric and space characters only
            const maxLength = 50; // Maximum length of the full name field
            if (fullName.length >= maxLength) {
              this.fullname_err_text = "Full name cannot exceed "+maxLength+" characters"
              return false;
            }
            else if (!regex.test(fullName) && fullName != '') {
              this.input.username = ''
              this.fullname_err_text = "Full name can only contain alphanumeric characters and spaces";
              return false;
            }
            return true;
          },
          validatePassword(){
            if(this.input.password && this.input.password.length >=30){
                this.$toasted.show('Characters Limit Exceeded!', {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
            }
        }
        },
        methods: {
          OngoogleLogin(){
              window.location.href = this.googleLogin
          },
        async Register(){
            if(this.validate()){
                let response= await UserController.signup(this.input,this.input.source,this.input.clickid,this.input.refererid)
                if(response.result){
                  
                  if(!response.message.isactive){

                      this.$router.replace("/auth/verification");
                      this.$toasted.show('Your Account is not Activated. Sending Verification code.', {theme: 'outline',position: "bottom-center", type: 'error', duration: 4000});
                    }
                }
                else{
                    this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                }
            } 
          },
          validateEmail(email) {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regex.test(email);
          },
          validate(){
            
            if(this.validateEmail(this.input.email)){
              if (/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(this.input.email)) {
              if(this.input.email == '' && this.input.password == ''){
                this.$toasted.show("Please enter a valid email address and password", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                return false;
              }
              if(this.input.email && this.input.password.length < 6){
                this.$toasted.show("password length should be more then 6 character", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                return false;
                }
              if(this.input.password != this.verify_password){
                this.input.password = ''
                this.verify_password=''
                this.$toasted.show("Password not match", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                return false;
              }
                return true;
            }
          }else{
              this.$toasted.show("Invalid Email", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
              return false;
            }
        },
        togglePassword(id){
          const password = document.getElementById(id);
          this.showPassword = this.showPassword === false ? true: false
          const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
          password.setAttribute('type', type);
        }
        }
    }
</script>

<style>
body {
  background-image: url("../assets/images/login-bg.jpg") !important;
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: cover;
  height: 100%;
}
</style>

<style scoped>
.card {
  box-shadow: 0 0 15px 10px #000000 !important;
}
button {
  background-color: #F7981E;
  color: #fefeff;
}
</style>